import type { FC } from 'react';
import React from 'react';
import { FormattedDate } from 'react-intl-next';

import { cssMap } from '@atlaskit/css';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

const styles = cssMap({
	dateWrapper: {
		borderColor: 'transparent',
		borderRadius: token('border.radius.050'),
	},
});

export interface DateInterface {
	timestamp: string;
}

/**
 *
 * @param param0
 *
 * There was an issue with the timestamp ADF gives which might be behind in other timezones.
 * For PST, the timestamp is roughly 13 hours behind.
 * Currently, adjusting the timestamp to match the Sydney timezone.
 *
 */
export const DateComponent: FC<DateInterface> = ({ timestamp }) => {
	return (
		<Box
			as="span"
			backgroundColor="color.background.neutral"
			padding="space.025"
			testId="data-component"
			xcss={styles.dateWrapper}
		>
			<FormattedDate
				value={new Date(parseInt(timestamp)).toLocaleString('en-US', {
					timeZone: 'Australia/Sydney',
				})}
				year="numeric"
				month="short"
				day="numeric"
			/>
		</Box>
	);
};
